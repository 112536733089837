import { FunctionComponent, useEffect, useRef } from 'react';
import { Redirect, RouteProps, useRouteMatch } from 'react-router-dom';
import { UserClaims } from '@okta/okta-auth-js';
import { SecureRoute, useOktaAuth } from '@okta/okta-react';

import { useAuthContext } from '../contexts';
import { ErrorBoundaryHoc, withAppLayout } from '../hocs';
import { Role } from '../types';
import { toastFlashMessage } from '../utils';
import datadog from '../utils/datadog';

interface PrivateRouteProps extends RouteProps {
  title: string;
  roles: Role[];
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  title,
  roles,
  component,
  ...routeProps
}) => {
  const { role, profile } = useAuthContext();
  const { oktaAuth, authState } = useOktaAuth();
  const match = useRouteMatch(routeProps);
  const pendingVerification = useRef(false);
  const latestUser = useRef<UserClaims>();
  const isActive =
    profile?.status?.toLowerCase() === 'active' ||
    routeProps.location?.pathname.startsWith('/user/csp');

  const handleLogout = () => {
    setTimeout(() => {
      localStorage.clear();
      oktaAuth.signOut();
    }, 2000);
  };

  const handleVerification = async () => {
    if (pendingVerification.current || latestUser.current) {
      return;
    }
    pendingVerification.current = true;
    latestUser.current = await oktaAuth.getUser();

    const user = latestUser.current;
    if (user) {
      if (!datadog.hasUser()) {
        datadog.setUser(user);
      }

      const latestRole =
        user.groups && user.groups.length > 0 ? user.groups[0] : Role.Reviewer;
      const latestCspUid = user.cspUid ?? '';
      if (
        profile?.status?.toLowerCase() === 'active' &&
        (latestRole !== role || latestCspUid !== profile.uid) &&
        !routeProps.location?.pathname?.startsWith('/user/csp')
      ) {
        toastFlashMessage(
          'Re-login required due to user role update',
          'success'
        );
        handleLogout();
      }
    } else {
      handleLogout();
    }
    pendingVerification.current = false;
  };

  useEffect(() => {
    if (!match) {
      return;
    }

    if (authState.isAuthenticated) {
      handleVerification();
    }
  }, [authState.isAuthenticated, match]);

  return isActive ? (
    <SecureRoute
      {...routeProps}
      component={
        authState.isAuthenticated && role
          ? withAppLayout(ErrorBoundaryHoc(component), {
              title,
              authorized: roles.includes(role),
            })
          : undefined
      }
    />
  ) : (
    <Redirect to="/" />
  );
};

export default PrivateRoute;
