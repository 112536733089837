import axios from 'axios';
import { DEV_BASE_URL } from '../../constants';
import { toastFlashMessage } from '../index';
import { extractErrorMessages, shouldIgnoreError } from './helper';

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = DEV_BASE_URL;
axiosInstance.interceptors.request.use(
  (config) => {
    const oktaTokenStorage = JSON.parse(
      localStorage.getItem('okta-token-storage')
    );
    if (oktaTokenStorage) {
      config.headers.Authorization = `Bearer ${oktaTokenStorage.accessToken.accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status >= 200 && response.status < 300) {
      response.ok = true;
    }
    return response;
  },
  (error) => {
    if (error.response?.status < 500) {
      if (
        error.response.data &&
        error.response.data.error &&
        Object.keys(error.response.data.error)
      ) {
        if (!error.config.silent) {
          const messages = extractErrorMessages(error.response.data);
          toastFlashMessage(messages, 'error');
        }
      } else if (shouldIgnoreError(error.response)) {
        //
      }
      return error.response;
    } else {
      if (!error.config.silent) {
        toastFlashMessage(
          'Unable to process the request due to technical error',
          'error'
        );
      }
      return error;
    }
  }
);

export default axiosInstance;
