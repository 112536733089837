import { Button, Box } from 'portal-commons';
import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { withRouter } from 'react-router-dom';
import { TableCell, TableRow } from '@material-ui/core';
import { faHourglass, faSquareXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { remapTCRStatus } from '../utils';
import { APP_ROLES } from '../../../../constants';
import { useAuthContext } from '../../../../contexts';
import { sortMnoList } from '../../../../utils';
import { globalGetService } from '../../../../utils/globalApiServices';
import { convertTimeWithTimezone } from '../../../../utils/time';

function PartnerCampaignListingRow(props) {
  const { data, type } = props;
  const { role } = useAuthContext();
  const [showMnoInfo, setShowMnoInfo] = useState(false);
  const [mnoInfo, setMnoInfo] = useState([]);
  const [height, setHeight] = useState(null);
  const carrierTermRef = useRef(null);
  const permissions = [
    ...APP_ROLES.GENERAL,
    ...(APP_ROLES[role] ? APP_ROLES[role] : []),
  ];

  const getMnoInfo = () => {
    if (mnoInfo.length) {
      setShowMnoInfo(true);
    } else {
      globalGetService(`csp/campaigns/${data.uid}/operations`).then(
        (response) => {
          console.log('response', response);
          if (response.status >= 200 && response.status < 300) {
            const mnoStatusInfo = response.data.records;
            mnoStatusInfo.forEach((item) => {
              item.rulesEngineResultsJson = JSON.parse(
                item.rulesEngineResultsJson
              );
              item.osrAttributesJson = JSON.parse(item.osrAttributesJson);
            });
            setMnoInfo(mnoStatusInfo);
            setShowMnoInfo(true);
          }
        }
      );
    }
  };

  useEffect(() => {
    if (showMnoInfo && carrierTermRef.current) {
      setHeight(carrierTermRef.current.clientHeight + 40);
    }
  }, [showMnoInfo]);

  const handleToggleCarrierTerm = (evt) => {
    evt.stopPropagation();
    const nextVisibility = !showMnoInfo;
    if (nextVisibility) {
      getMnoInfo();
    } else {
      setShowMnoInfo(false);
      setHeight(null);
    }
  };

  return (
    <React.Fragment>
      {type === 'history' ? (
        <TableRow
          className={height ? 'open link' : 'link'}
          style={{
            height: mnoInfo ? `${10 + height}px` : '40px',
            cursor: 'pointer',
          }}
          onClick={() =>
            props.history.push({
              pathname: `/cnp-campaigns/${data.uid}`,
              state: {
                goBackPage: `${props.location.pathname}${
                  props.location.search ? props.location.search : ''
                }`,
              },
            })
          }
        >
          <TableCell align="left">{data.uid}</TableCell>
          <TableCell align="left">{data.brandUid}</TableCell>
          <TableCell align="left">{data.usecase}</TableCell>
          <TableCell align="left">{data.downstreamCnpName}</TableCell>
          <TableCell align="left">
            {data.sharedOnDate
              ? convertTimeWithTimezone(
                  data.sharedOnDate,
                  undefined,
                  'MM/DD/YYYY'
                )
              : ''}
          </TableCell>
          <TableCell align="left">
            {data.upstreamCnpName ? data.upstreamCnpName : '--'}
          </TableCell>
          <TableCell align="left">
            {data.sharedToDate
              ? convertTimeWithTimezone(
                  data.sharedToDate,
                  undefined,
                  'MM/DD/YYYY'
                )
              : '--'}
          </TableCell>
          <TableCell align="left">
            <span className={`more-icon ${height ? 'active' : ''}`}>
              <MoreHorizIcon onClick={handleToggleCarrierTerm} />
            </span>
            {showMnoInfo && !!mnoInfo.length && (
              <CarrierTerms mnoList={mnoInfo} ref={carrierTermRef} />
            )}
          </TableCell>
          <TableCell align="left">{remapTCRStatus(data.status)}</TableCell>
        </TableRow>
      ) : (
        <TableRow
          className={height ? 'open link' : 'link'}
          style={{
            height: mnoInfo ? `${10 + height}px` : '40px',
            cursor: 'pointer',
          }}
          onClick={() =>
            props.history.push({
              pathname: `/cnp-campaigns/${data.uid}`,
              state: {
                goBackPage: `${props.location.pathname}${
                  props.location.search ? props.location.search : ''
                }`,
              },
            })
          }
        >
          <TableCell align="left" style={{ width: 100 }}>
            {data.uid}
          </TableCell>
          <TableCell align="left" style={{ width: 79 }}>
            {data.brandUid}
          </TableCell>
          <TableCell align="left" className="ellipsis" style={{ width: '10%' }}>
            <span title={data.usecase}>{data.usecase}</span>
          </TableCell>
          <TableCell align="left" className="ellipsis" style={{ width: '10%' }}>
            <span title={data.downstreamCnpName}>{data.downstreamCnpName}</span>
          </TableCell>
          <TableCell align="left" style={{ width: 91 }}>
            {data.registeredOnDate
              ? convertTimeWithTimezone(
                  data.registeredOnDate,
                  undefined,
                  'MM/DD/YYYY'
                )
              : ''}
          </TableCell>
          <TableCell align="left" style={{ width: 145 }}>
            {data.sharedOnDate
              ? convertTimeWithTimezone(
                  data.sharedOnDate,
                  undefined,
                  'MM/DD/YYYY'
                )
              : ''}
          </TableCell>
          <TableCell align="left" style={{ width: 92 }}>
            <span className={`more-icon ${height ? 'active' : ''}`}>
              <MoreHorizIcon onClick={handleToggleCarrierTerm} />
            </span>
            {showMnoInfo && !!mnoInfo.length && (
              <CarrierTerms
                mnoList={sortMnoList(mnoInfo, [
                  'rulesEngineResultsJson',
                  'mno',
                ])}
                ref={carrierTermRef}
              />
            )}
          </TableCell>
          <TableCell align="left" className="ellipsis" style={{ width: 85 }}>
            <span title={remapTCRStatus(data.status)}>
              {remapTCRStatus(data.status)}
            </span>
          </TableCell>

          <TableCell align="left" style={{ width: 223 }}>
            {permissions.includes('ELECT_CNP') &&
            permissions.includes('REJECT_CNP') ? (
              <div className="cnp-action-button-wrapper">
                {data.sharedToStatus === 'DECLINED' ? (
                  <div className="shared-to-status">
                    <Box
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box margin={{ right: 'xxs' }}>
                        <FontAwesomeIcon icon={faSquareXmark} />
                      </Box>
                      REJECTED
                    </Box>
                  </div>
                ) : (
                  <div className="shared-to-status">
                    <Box
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box margin={{ right: 'xxs' }}>
                        <FontAwesomeIcon icon={faHourglass} />
                      </Box>
                      PENDING
                    </Box>
                  </div>
                )}
                {data.status !== 'EXPIRED' && (
                  <Button
                    shape="rounded"
                    disabled={data.status === 'EXPIRED'}
                    color="secondary"
                    className="elect-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (data.status !== 'EXPIRED') {
                        props.handleClickAction(
                          'electCnp',
                          data.uid,
                          '',
                          data.usecase
                        );
                      }
                    }}
                  >
                    {data.sharedToDate ? 'Re-Elect' : 'Elect'}
                  </Button>
                )}
                {data.sharingStatus === 'PENDING' &&
                  data.status !== 'EXPIRED' && (
                    <Button
                      shape="rounded"
                      className="reject-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        props.handleClickAction(
                          'rejectCnp',
                          data.uid,
                          data,
                          data.usecase
                        );
                      }}
                    >
                      Decline
                    </Button>
                  )}
              </div>
            ) : null}
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
export default withRouter(PartnerCampaignListingRow);

const CarrierTerms = forwardRef(({ mnoList }, ref) => {
  return (
    <div className="carrier-term" id="mnoInfoList" ref={ref}>
      {mnoList.map((item, index) => (
        <span key={index}>
          <p className="paragraph">
            <span className="mno-name">{item.rulesEngineResultsJson.mno}</span>
            {item.rulesEngineResultsJson.mno === 'T-Mobile' ? (
              <>
                Brand Tier:
                <span className="value">
                  {item.rulesEngineResultsJson.brandTier}
                </span>
              </>
            ) : (
              <>
                Message Class:
                <span className="value">
                  {item.rulesEngineResultsJson.msgClass || 'N/A'}
                </span>
              </>
            )}
            <br />
            MNO Status:
            <span className="value">
              {item.status.toLowerCase() === 'approved'
                ? 'REGISTERED'
                : item.status}
            </span>
          </p>
        </span>
      ))}
    </div>
  );
});
