import { CspProfile, User } from '../types';

const UserKey = 'user';
const ProfileKey = 'profile';

export const saveUser = (user: User) => {
  localStorage.setItem(UserKey, JSON.stringify(user));
};

export const restoreUser = () => {
  const value = localStorage.getItem(UserKey);
  return value ? (JSON.parse(value) as User) : undefined;
};

export const saveProfile = (profile: CspProfile) => {
  localStorage.setItem(ProfileKey, JSON.stringify(profile));
};

export const restoreProfile = () => {
  const value = localStorage.getItem(ProfileKey);
  return value ? (JSON.parse(value) as CspProfile) : undefined;
};
