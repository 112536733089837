import { CspProfile, User } from '../types';
import { globalGetService } from '../utils/globalApiServices';

export const getUser = async () => {
  const response = await globalGetService('user');
  if (response.ok) {
    return response.data as User;
  }
};

export const getCspProfile = async () => {
  const response = await globalGetService('csp/profile');
  if (response.ok) {
    return response.data as CspProfile;
  }
};
