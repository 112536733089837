import React from 'react';
import { Container } from '@material-ui/core';
import { ApiKeysListing } from '../components';
import '../../../../assets/styles/integrations-module.scss';

export default function Integrations() {
  return (
    <section className="integrations-section" data-testid="integration">
      <Container maxWidth={false} className="integrations-container">
        <ApiKeysListing />
      </Container>
    </section>
  );
}
