import { capitalize } from 'lodash';
import { useState, useEffect } from 'react';
import { Menu, MenuItem, Fade, Grid } from '@material-ui/core';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';

import ConfirmationDialog from './ConfirmationDialog';
import { MIGRATION_STATUS_LABEL_MAP } from '../constants';
import { remapTCRStatus } from '../utils';
import { useAuthContext } from '../../../../contexts';
import { Role } from '../../../../types';
import ToolTip from '../../../../ui_elements/ToolTip';
import { convertTimeWithTimezone } from '../../../../utils/time';

export default function CampaignDetailsBasic(props) {
  const { campaign, renewalData, migrationInfo } = props;
  const { role } = useAuthContext();
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const isRoleReviewer = role === Role.Reviewer;

  const handleClick = (e) => {
    setOpen(true);
    setAnchorEl(e.currentTarget);
  };
  const handleRequestClose = (e) => {
    setOpen(false);
  };

  const getMigrationStatus = () => {
    const { status = 'No' } = migrationInfo ?? {};
    return MIGRATION_STATUS_LABEL_MAP[status] ?? status;
  };

  return (
    <>
      <Grid
        container
        className="basic-info"
        spacing={2}
        data-testid="campaignDetailBasic"
      >
        <Grid item xs={4}>
          <div className="column">
            <h3 className="heading2" data-testid="campaignDetailBrandName">
              <span className="title">Brand Name:</span>
              <span className="val">
                <a
                  data-testid="campaignDetailBasicBrandNameButton"
                  className="primary-color"
                  href={`/brands/${campaign.brandUid}`}
                >
                  {campaign.brandName}
                </a>
              </span>
            </h3>
            {campaign.subUsecases ? (
              <div className="campaign-usecase-wrapper">
                <h3
                  className="heading2"
                  aria-owns={open ? 'simple-menu' : null}
                  aria-haspopup="true"
                  // onMouseOver={handleClick}
                >
                  <span>Use-Case:</span>
                  <span
                    onClick={handleClick}
                    className="val"
                    data-testid="campaignDetailBasicUseCaseButton"
                  >
                    {campaign.usecase}
                    <ExpandMoreOutlinedIcon
                      className={`dropdown-arrow ${open ? 'open' : ''}`}
                    />
                  </span>
                </h3>
                <Menu
                  id="simple-menu"
                  data-testid="campaignDetailBasicSubUseCaseMenu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleRequestClose}
                  className="campaign-subusecases-menu"
                  MenuListProps={
                    {
                      // onMouseLeave: handleRequestClose
                    }
                  }
                  TransitionComponent={Fade}
                >
                  {campaign.subUsecases.split(',').map((sub, index) => (
                    <MenuItem
                      key={index}
                      onClick={handleRequestClose}
                      data-testid={`campaignDetailBasicSubUseCase-${sub}`}
                    >
                      {sub}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            ) : (
              <h3
                className="heading2"
                data-testid="campaignDetailBasicSubUseCase"
              >
                <span>Use-Case:</span>
                {campaign.usecase}
              </h3>
            )}
            <h3 className="heading2" data-testid="campaignDetailBasicStatus">
              <span className="title">TCR Status:</span>
              <span
                className={`
                            val
                            ${
                              remapTCRStatus(campaign.status) === 'ACTIVE'
                                ? 'primary-color'
                                : remapTCRStatus(campaign.status) ===
                                  'DEACTIVATED'
                                ? 'error-color'
                                : ''
                            }
                            `}
              >
                {capitalize(remapTCRStatus(campaign.status))}
              </span>
            </h3>
          </div>
        </Grid>

        <Grid item xs={4} className="border-left">
          <div className="column">
            <h3
              className="heading2"
              data-testid="campaignDetailBasicCreateDate"
            >
              <span>Registered on:</span>
              {campaign.createDate
                ? convertTimeWithTimezone(
                    campaign.createDate,
                    undefined,
                    'MM/DD/YYYY'
                  )
                : 'N/A'}
            </h3>
            <h3
              className="heading2"
              data-testid="campaignDetailBasicExpirationDate"
            >
              {remapTCRStatus(campaign.status) === 'DEACTIVATED' ? (
                <>
                  <span>Expiration Date:</span>
                  {campaign.expirationDate
                    ? convertTimeWithTimezone(
                        campaign.expirationDate,
                        undefined,
                        'MM/DD/YYYY'
                      )
                    : 'N/A'}
                </>
              ) : (
                <>
                  <span>
                    {campaign.autoRenewal ? 'Renewal' : 'Expiration'} Date:
                  </span>
                  {campaign.nextRenewalOrExpirationDate
                    ? convertTimeWithTimezone(
                        campaign.nextRenewalOrExpirationDate,
                        undefined,
                        'MM/DD/YYYY'
                      )
                    : 'N/A'}
                </>
              )}
            </h3>
          </div>
        </Grid>
        <Grid item xs={4} className="border-left">
          <div className="column">
            <h3 className="heading2" data-testid="campaignDetailBasicMigration">
              <span className="title">Migration:</span>
              <span className="val primary-color">{getMigrationStatus()}</span>
            </h3>
            {remapTCRStatus(campaign.status) !== 'DEACTIVATED' && (
              <div className="auto-renewal-wrapper">
                <div
                  className={`${isRoleReviewer ? 'disabled' : ''} ${
                    campaign.autoRenewal
                      ? 'auto-renewal-button yes'
                      : 'auto-renewal-button no'
                  } ${campaign.status == 'EXPIRED' ? 'disabled' : ''}`}
                  onClick={() =>
                    campaign.autoRenewal
                      ? setOpenModal(true)
                      : props.handleSubmitEdit({ autoRenewal: true })
                  }
                  data-testid="campaignDetailBasicRenewalButton"
                >
                  {campaign.autoRenewal ? (
                    <>
                      Yes <span></span>
                    </>
                  ) : (
                    <>
                      <span></span>No
                    </>
                  )}
                </div>
                <p className="paragraph">Auto Renewal</p>
                <ToolTip
                  title="If set to “No” , the campaign will be deactivated at the end of the active period."
                  placement="right-end"
                  style={{ top: '3px' }}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>

      <ConfirmationDialog
        open={openModal}
        type="renewal"
        handleCancel={() => setOpenModal(false)}
        handleSuccess={() => {
          setOpenModal(false);
          props.handleSubmitEdit({ autoRenewal: false });
        }}
        text={renewalData ? renewalData.text : ''}
      />
    </>
  );
}
