import React, { useEffect } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  Redirect,
} from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { OktaAuth } from '@okta/okta-auth-js';
import { Security, SecureRoute } from '@okta/okta-react';

import CustomLoginCallback from './CustomLoginCallback';
import Initializer from './Initializer';
import Protected from './Protected';
import PageNotFound from './404';
import { authRoutes } from './applications/auth/authRoutes';
import cspRoutes from './applications/CspPortal/routes';
import PrivateRoute from './components/PrivateRoute';
import { OKTA_CLIENT_ID, OKTA_ISSUER_URL } from './constants';
import { GetCspVerificationHoc, ErrorBoundaryHoc } from './hocs';

const oktaAuth = new OktaAuth({
  issuer: OKTA_ISSUER_URL,
  clientId: OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/implicit/callback`,
  pkce: false,
  postLogoutRedirectUri: `${window.location.origin}/login`,
});

const AppWithRouterAccess = () => {
  const history = useHistory();
  const location = useLocation();

  const onAuthRequired = () => {
    history.push('/login');
  };

  const computeViewName = (routeMatches) => {
    let viewName = '';
    for (let index = 0; index < routeMatches.length; index++) {
      const routeMatch = routeMatches[index];
      const { path } = routeMatch;

      if (!path) {
        return null;
      }

      if (path.startsWith('/')) {
        viewName = path;
      } else {
        viewName += viewName.endsWith('/') ? path : `/${path}`;
      }
    }

    return viewName || '/';
  };

  useEffect(() => {
    const allRoutes = [...authRoutes, ...cspRoutes];
    const routeMatches = allRoutes.filter((route) => {
      const regex = new RegExp(route.path.replace(/:[^\/]+/g, '\\w+'));
      return regex.test(location.pathname);
    });
    const viewName = routeMatches && computeViewName(routeMatches);
    if (viewName) {
      datadogRum.startView({ name: viewName });
    }
  }, [location.pathname]);

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={onAuthRequired}>
      <Initializer />
      <Switch>
        <Route
          key="callback"
          exact
          path="/implicit/callback"
          component={CustomLoginCallback}
        />
        <Route
          key="home"
          exact
          path="/"
          component={ErrorBoundaryHoc(GetCspVerificationHoc(Protected))}
        />
        <SecureRoute
          key="protected"
          exact
          path="/protected"
          component={Protected}
        />
        {authRoutes.map((config) => (
          <Route
            key={config.key}
            exact
            path={config.path}
            component={config.component}
          />
        ))}
        {cspRoutes.map((config) => (
          <PrivateRoute
            key={config.key}
            exact
            path={config.path}
            title={config.title}
            component={config.component}
            roles={config.roles}
          />
        ))}
        <Route path="/404" component={PageNotFound} key="404" />
        <Redirect from="*" to="/404" />
      </Switch>
    </Security>
  );
};

export default AppWithRouterAccess;
