import axiosInstance from './apiInterceptor';

export const globalGetService = (url, data, configs = {}) => {
  if (typeof configs === 'string') {
    /* 
      in legacy code, 3rd argument was "responseType: string = 'json'" 
      CSV / attachments are handled as arraybuffer
    */
    // FIXME : remove this block after all references are updated
    return axiosInstance.get(url, { params: data, responseType: configs });
  }
  return axiosInstance.get(url, {
    params: data,
    responseType: 'json',
    ...configs,
  });
};

export const globalPostService = (url, data, configs = {}) => {
  return axiosInstance.post(url, data, configs);
};

export const globalPutService = (url, data, configs = {}) => {
  return axiosInstance.put(url, data, configs);
};

export const globalDeleteService = (url, data, configs = {}) => {
  return axiosInstance.delete(url, { data, ...configs });
};
