export const extractErrorMessages = (responseData) => {
  const messages = [];
  const errorCode = responseData.code;

  Object.keys(responseData.error).forEach((keyName) => {
    responseData.error[keyName].forEach((errorMsg) => {
      if (errorMsg) {
        if (isIgnoredError(errorMsg, keyName, errorCode)) {
          // Ignore error
        } else if (isAccountExistsError(errorMsg)) {
          messages.push('Account with this email address already exists');
        } else if (isPasswordRequirementsError(errorMsg)) {
          messages.push('Password requirements are not met');
        } else if (errorCode === 537) {
          messages.push(formatCustomErrorMessage(keyName, errorMsg));
        } else {
          messages.push(errorMsg);
        }
      }
    });
  });

  return messages;
};

const isIgnoredError = (errorMsg, keyName, errorCode) => {
  return (
    errorMsg.includes(`"code":502,"description":"CSP not found"`) ||
    errorMsg.includes('User CSP profile not found') ||
    errorMsg.includes('CSP not found') ||
    errorMsg.includes(
      `"code":502,"field":"cspId","description":"record not found"`
    ) ||
    (keyName === 'cspUid' && errorMsg.includes('record not found')) ||
    errorCode === 525 ||
    errorMsg.includes(
      'Vetting re-submission not allowed with same brand information.'
    ) ||
    errorCode === 535
  );
};

const isAccountExistsError = (errorMsg) => {
  return errorMsg.includes(
    'HTTP 400, Okta E0000001 (Api validation failed: login - login: An object with this field already exists in the current organization)'
  );
};

const isPasswordRequirementsError = (errorMsg) => {
  return errorMsg.includes(
    'HTTP 400, Okta E0000001 (Api validation failed: password - password: Password requirements were not met. Password requirements: at least 12 characters, a lowercase letter, an uppercase letter, a number, a symbol, no parts of your username, does not include your first name, does not include your last name. Your password cannot be any of your last 5 passwords.)'
  );
};

const formatCustomErrorMessage = (keyName, errorMsg) => {
  // Custom Error message for Active Campaign Check for EIN Update
  // https://campaignreg.atlassian.net/browse/TCR-6355
  const remapKeyName = {
    ein: 'EIN',
    einIssuingCountry: 'EIN Issuing Country',
    entityType: 'Entity Type',
    companyName: 'Legal Company Name',
  };

  return `${remapKeyName[keyName] || keyName}, ${errorMsg}`;
};

export const shouldIgnoreError = (response) => {
  return (
    !response.config.url.includes('profile') &&
    response.error &&
    [102, 319].includes(response.error[0].code)
  );
};
